import React from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import { BsGithub, BsLinkedin, BsTwitter, BsYoutube } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import styled from "styled-components";
import ResumePdf from "../../assets/pdfs/resume.pdf";

const Nav = styled.nav`
  height: 90vh;
  display: flex;
  background-color: #181818;
  justify-content: space-between;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  @media (max-width: 480px) {
    position: absolute;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
  }
`;

const NavigationLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const LinkTag = styled(Link)`
  text-align: center;
  padding: 4px 0;
  text-decoration: none;
  width: 100%;
  display: block;
  cursor: pointer;
  font-size: 1.6rem;
  color: #909096;
  transition: 0.3s ease-out all;
  border-bottom: 3px solid #282828;
  z-index: 10000;
  &:hover,
  &:active {
    color: #08fdd8;
    cursor: pointer;
  }

  @media (max-width: 780px) {
    font-size: 1.2rem;
  }
  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10000;
  @media (max-width: 480px) {
    display: flex;
    justify-content: space-between;
    padding: 0 2rem 3rem 2rem;
  }
`;

const Github = styled(BsGithub)`
  width: 2.4rem;
  height: 2.4rem;
  color: #fff;
  cursor: pointer;
  z-index: 10000;
  @media (max-width: 780px) {
    width: 1.4rem;
    height: 1.4rem;
  }
  @media (max-width: 480px) {
    width: 2.5rem;
    height: 2.5rem;
  }
`;
const CloseIcon = styled(IoMdClose)`
  color: #fff;
  display: none;
  cursor: pointer;
  z-index: 10000;
  @media (max-width: 480px) {
    display: block;
    width: 4rem;
    height: 4rem;
  }
`;

const LinkedIn = styled(BsLinkedin)`
  width: 2.4rem;
  height: 2.4rem;
  color: #fff;
  cursor: pointer;
  z-index: 10000;
  @media (max-width: 780px) {
    width: 1.4rem;
    height: 1.2rem;
  }
  @media (max-width: 480px) {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

const Twitter = styled(BsTwitter)`
  width: 2.4rem;
  height: 2.4rem;
  color: #fff;
  cursor: pointer;
  z-index: 10000;
  @media (max-width: 780px) {
    width: 1.4rem;
    height: 1.4rem;
  }
  @media (max-width: 480px) {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

const Youtube = styled(BsYoutube)`
  width: 2.4rem;
  height: 2.4rem;
  color: #fff;
  cursor: pointer;
  z-index: 10000;
  @media (max-width: 780px) {
    width: 1.4rem;
    height: 1.4rem;
  }
  @media (max-width: 480px) {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

const Resume = styled.a`
  width: 2.4rem;
  height: 2.4rem;
  color: #fff;
  cursor: pointer;
  text-align: center;
  padding: 4px 0;
  text-decoration: none;
  width: 100%;
  display: block;
  font-size: 1.6rem;
  color: #909096;
  transition: 0.3s ease-out all;
  border-bottom: 3px solid #282828;
  z-index: 10000;
  &:hover {
    color: #08fdd8;
  }
  @media (max-width: 780px) {
    font-size: 1.2rem;
  }
  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const PhoneNavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 3rem 2rem 0 2rem;
`;

function NavBar(props) {
  const { handleClick } = props;

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = ResumePdf;
    link.download = "resume.pdf";
    link.click();
  };

  return (
    <Nav>
      <Link to="/">
        <PhoneNavContainer>
          <Logo />
          <CloseIcon onClick={handleClick} />
        </PhoneNavContainer>
      </Link>
      <NavigationLinks>
        <LinkTag to="/" onClick={handleClick}>
          HOME
        </LinkTag>
        <LinkTag to="about" onClick={handleClick}>
          ABOUT
        </LinkTag>
        <LinkTag to="myskills" onClick={handleClick}>
          MY SKILLS
        </LinkTag>
        <LinkTag to="projects" onClick={handleClick}>
          MY PROJECTS
        </LinkTag>
        <LinkTag to="contact" onClick={handleClick}>
          CONTACT ME
        </LinkTag>
        <Resume to={ResumePdf} onClick={handleDownload}>
          MY RESUME
        </Resume>
      </NavigationLinks>
      <SocialMedia>
        <a href="https://github.com/arthurkyambadde">
          <Github />
        </a>
        <a href="https://www.linkedin.com/in/arthur-kyambadde-904960101/">
          <LinkedIn />
        </a>
        <a href="https://www.youtube.com/channel/UCQHoUcHnfu79usi3MSMFGyA">
          <Youtube />
        </a>
        <a href="https://twitter.com/artkyamvadde">
          <Twitter />
        </a>
      </SocialMedia>
    </Nav>
  );
}

export default NavBar;
