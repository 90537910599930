import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import aboutImg from "../../assets/picture/about.png";

const AboutContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
  padding: 0 7rem;
  width: 85%;
  font-family: "Roboto", sans-serif;
  @media (max-width: 1024px) {
  }
  @media (max-width: 480px) {
    padding: 0;
    width: 100vw;
    overflow: hidden;
  }
`;

const MainAbout = styled.h2`
  align-self: baseline;
  color: #08fdd8;
  margin: 0;
  font-weight: 600;
  font-size: 8rem;
  @media (max-width: 780px) {
    font-size: 3.5rem;
  }
  @media (max-width: 1024px) {
    font-size: 5rem;
  }
`;

const AboutContentContainer = styled.div`
  width: 40%;
  z-index: 5;
  @media (max-width: 780px) {
    width: 50%;
  }
  @media (max-width: 780px) {
    width: 60%;
  }
  @media (max-width: 480px) {
    width: 100%;
    padding: 3rem;
  }
`;

const AboutPara = styled.p`
  font-size: 1.4rem;
  line-height: 1.5;
  word-spacing: 0.1rem;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  @media (max-width: 780px) {
    font-size: 1.1rem;
  }
  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const AboutLink = styled(Link)`
  color: #08fdd8;
  transition: color 0.3s;
  text-decoration: none;
  font-size: 1.4rem;
  cursor: pointer;
  @media (max-width: 780px) {
    font-size: 1.1rem;
  }
  @media (max-width: 480px) {
    display: block;
    font-size: 1.5rem;
    margin-top: 4rem;
  }
`;

const AboutImage = styled.img`
  width: 40%;
  position: absolute;
  right: 8rem;
  @media (max-width: 780px) {
    right: 1rem;
    width: 40%;
  }
  @media (max-width: 1024px) {
    right: 4rem;
    width: 40%;
  }
  @media (max-width: 480px) {
    display: none;
  }
`;

function About() {
  return (
    <AboutContainer>
      <AboutContentContainer>
        <MainAbout>I'm Arthur Kyambadde.</MainAbout>
        <AboutPara>
          A Front-End Developer located in Uganda. I have a serious passion for
          developing Fast, Intuitive, Responsive and Dynamic web applications
        </AboutPara>
        <AboutPara>
          Well-organised person, problem solver, independent employee with high
          attention to detail. Fan of Arsenal football club, an average swimmer
          who loves watching TV series and love traveling the world.
        </AboutPara>
        <AboutPara>
          I am so interested in the entire frontend spectrum and working on
          ambitious projects with positive thinking people.
        </AboutPara>
        <AboutLink to="/contact">Lets work on something together</AboutLink>
      </AboutContentContainer>
      <AboutImage src={aboutImg} />
    </AboutContainer>
  );
}

export default About;
