import styled from "styled-components";
import contactImg from "../../assets/picture/contactme.png";
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import MyModal from "./StatusModal";

const ContactMeContainer = styled.div`
  height: 100vh;
  display: flex;
  gap: 2rem;
  flex-direction: row;
  align-items: center;
  font-family: "Roboto", sans-serif;
  padding: 0 4rem;
  @media (max-width: 780px) {
    gap: 1rem;
    overflow: hidden;
  }
  @media (max-width: 480px) {
    display: flex;
    align-items: center;
  }
`;

const ContactInfo = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  @media (max-width: 780px) {
    width: 40%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const MainContact = styled.h2`
  color: #08fdd8;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 4rem;
  font-size: 7.3rem;
  line-height: 6.7rem;
  @media (max-width: 780px) {
    font-weight: 600;
    font-size: 3.5rem;
    margin-bottom: 0rem;
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const ContactPara = styled.p`
  margin-bottom: 1.5rem;
  color: #fff;
  font-size: 1.6rem;
  line-height: 1.5;
  @media (max-width: 780px) {
    font-size: 1.3rem;
  }
`;

const NameEmailContainer = styled.div`
  height: 5rem;
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
`;

const NameInput = styled.input`
  width: 50%;
  height: 5rem;
  border: none;
  outline: none;
  color: #8d8d8d;
  background: #2b2b2b;
  padding: 0 1rem;
`;

const Subject = styled.input`
  height: 5rem;
  border: none;
  outline: none;
  color: #8d8d8d;
  background: #2b2b2b;
  padding: 0 1rem;
  margin-bottom: 1.5rem;
`;

const Message = styled.textarea`
  border: none;
  outline: none;
  color: #8d8d8d;
  background: #2b2b2b;
  padding: 1rem 1rem 0 1rem;
  margin-bottom: 4rem;
  resize: none;
  @media (max-width: 480px) {
    height: 100px;
  }
`;

const SendButton = styled.input`
  color: #08fdd8;
  background: none;
  font-size: 1.4rem;
  letter-spacing: 0.4rem;
  text-decoration: none;
  margin-top: 2rem;
  position: relative;
  width: 100%;
  max-width: 22rem;
  height: 5rem;
  display: block;
  border: 1px solid #08fdd8;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: baseline;
  transition: all 0.3s;
  z-index: 2;
  transition: all 0.35s ease-out-all;

  &:hover {
    color: #111;
    background: #08fdd8;
    border: none;
  }
  &:hover:after {
    width: 100%;
  }
  @media (max-width: 780px) {
    margin-top: 1rem;
    max-width: 40%;
    letter-spacing: 0.25rem;
  }
  @media (max-width: 480px) {
    min-width: 15rem;
    padding: 0 2rem;
    margin-bottom: 2rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContactMeImg = styled.img`
  width: 40%;
  position: absolute;
  right: 6rem;
  @media (max-width: 768px) {
    right: 1rem;
  }
  @media (max-width: 480px) {
    display: none;
  }
`;

function ContactMe() {
  let formSumbmissionStatus;
  const form = useRef();

  const [showModal, setShowModal] = useState(true);
  const [resultText, setResultText] = useState("");

  if (resultText === "OK") {
    formSumbmissionStatus = (
      <MyModal
        showModal={showModal}
        setShowModal={setShowModal}
        className={showModal ? "show" : ""}
      />
    );
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_zruf4me",
        "arthur_contact_form",
        form.current,
        "eRjfqdzkOw8-2rQGo"
      )
      .then(
        (result) => {
          setResultText(result.text);
          setShowModal(true);
        },
        (error) => {
          setResultText(error.text);
        }
      );
  };

  return (
    <ContactMeContainer>
      <ContactInfo id="Arthur_contact_form">
        <MainContact>Contact me</MainContact>
        <ContactPara>
          I’m interested in freelance and full-time opportunities especially
          ambitious or large projects. However, if you have other request or
          question, don’t hesitate to use the form.
        </ContactPara>
        <ContactForm ref={form} onSubmit={sendEmail}>
          <NameEmailContainer>
            <NameInput type="text" placeholder="Name" name="user_name" />
            <NameInput type="email" placeholder="Email" name="user_email" />
          </NameEmailContainer>
          <Subject type="text" placeholder="Subject" name="subject" />
          <Message
            placeholder="Message"
            rows="12"
            cols="80"
            name="message"
          ></Message>
          <ButtonContainer href="#">
            <SendButton type="submit" value="Send Message!" />
          </ButtonContainer>
        </ContactForm>
      </ContactInfo>
      <ContactMeImg src={contactImg} />
      {formSumbmissionStatus}
    </ContactMeContainer>
  );
}

export default ContactMe;
