import React from "react";
import styled from "styled-components";
import ArthurLogo from "../../assets/picture/Arthurlogo.png";
import { motion } from "framer-motion";

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoImg = styled(motion.img)`
  height: 7rem;
  cursor: pointer;
  position: relative;
  @media (max-width: 780px) {
    height: 5rem;
  }
  @media (max-width: 480px) {
    height: 4rem;
  }
`;

function Logo() {
  return (
    <LogoContainer>
      <LogoImg
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 1.5 }}
        src={ArthurLogo}
        alt="arthur's logo"
      />
    </LogoContainer>
  );
}

export default Logo;
