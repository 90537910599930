import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const SkillsCOntainer = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  padding: 0 8rem;
  gap: 8rem;
  font-family: "Roboto", sans-serif;
  @media (max-width: 780px) {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 8rem 3rem 5rem 3rem;
  }
`;

const SkillsCOntent = styled.div`
  width: 100%;
`;

const MainSkillsHeading = styled.h2`
  color: #08fdd8;
  font-weight: 400;
  position: relative;
  margin-bottom: 4rem;
  font-size: 7.3rem;
  @media (max-width: 780px) {
    font-size: 3.5rem;
  }
  @media (max-width: 480px) {
    margin-bottom: 1.5rem;
    text-align: center;
  }
`;

const SkillsPara = styled.p`
  font-size: 1.4rem;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  @media (max-width: 780px) {
    font-size: 1.1rem;
  }
  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const AboutLink = styled(Link)`
  color: #08fdd8;
  margin: 0 5px;
  transition: color 0.3s;
  font-size: 1.4rem;
  text-decoration: none;
`;

const SkillsContainer = styled.div`
  width: 100%;
`;

const SkillContainer = styled.div`
  margin-bottom: 2rem;
  @media (max-width: 780px) {
    margin-bottom: 1rem;
  }
`;

const Skill = styled.span`
  font-size: 1.8rem;
  color: #fff;
  display: block;
  margin-bottom: 0.7rem;
  @media (max-width: 780px) {
    margin-bottom: 0.5rem;
    font-size: 1.4rem;
  }
`;

const FrontEndBar = styled.div`
  background: #08fdd8;
  height: 0.1rem;
  width: 100%;
  margin: none;
  margin-bottom: 7px;
  border-radius: 0.8rem;
`;
const JavascriptBar = styled.div`
  width: 100%;
  height: 0.1rem;
  background: linear-gradient(to right, red 95%, #2a2a2a 10%);
  margin: none;
  margin-bottom: 7px;
  border-radius: 0.8rem;
`;
const ReactJsBar = styled.div`
  width: 100%;
  height: 0.1rem;
  background: linear-gradient(to right, rgb(210, 108, 213) 100%, #2a2a2a 10%);
  margin: none;
  margin-bottom: 7px;
  border-radius: 0.8rem;
`;
const NextJsBar = styled.div`
  width: 100%;
  height: 0.1rem;
  background: linear-gradient(to right, #ffff00 90%, #2a2a2a 10%);
  margin: none;
  margin-bottom: 7px;
  border-radius: 0.8rem;
`;
const RemixJsBar = styled.div`
  width: 100%;
  height: 0.1rem;
  background: linear-gradient(to right, #4ea24e 90%, #2a2a2a 10%);
  margin: none;
  margin-bottom: 7px;
  border-radius: 0.8rem;
`;
const HTMLBar = styled.div`
  width: 100%;
  height: 0.1rem;
  background: linear-gradient(to right, #e69500 100%, #2a2a2a 10%);
  margin: none;
  margin-bottom: 7px;
  border-radius: 0.8rem;
`;
const CSSBar = styled.div`
  width: 100%;
  height: 0.1rem;
  background: linear-gradient(to right, #f33a6a 100%, #2a2a2a 10%);
  margin: none;
  margin-bottom: 7px;
  border-radius: 0.8rem;
`;
const JestBar = styled.div`
  width: 100%;
  height: 0.1rem;
  background: linear-gradient(to right, #b4c424 80%, #2a2a2a 10%);
  margin: none;
  margin-bottom: 7px;
  border-radius: 0.8rem;
`;

function MySkills() {
  return (
    <SkillsCOntainer>
      <SkillsCOntent>
        <MainSkillsHeading>Skills & Experince</MainSkillsHeading>
        <SkillsPara>
          I am a freelance web-developer with a four years experience in the
          industry, I've done remote work for agencies, for startups, and
          collaborated with talented people to create web products for both
          business and consumer use.
        </SkillsPara>
        <SkillsPara>
          I create successful responsive websites that are fast, easy to use,
          and built with best practices. The main area of my expertise is
          front-end development, HTML, CSS, JS, building small and medium web
          apps, custom plugins, features, animations, and coding interactive
          layouts.
        </SkillsPara>
        <SkillsPara>
          For more deatils just
          <AboutLink to="/contact">Contact</AboutLink>
          me
        </SkillsPara>
      </SkillsCOntent>
      <SkillsContainer>
        <SkillContainer>
          <Skill>Front-end</Skill>
          <FrontEndBar />
        </SkillContainer>
        <SkillContainer>
          <Skill>JavaScript</Skill>
          <JavascriptBar />
        </SkillContainer>
        <SkillContainer>
          <Skill>ReactJs</Skill>
          <ReactJsBar />
        </SkillContainer>
        <SkillContainer>
          <Skill>NextJs</Skill>
          <NextJsBar />
        </SkillContainer>
        <SkillContainer>
          <Skill>Remix</Skill>
          <RemixJsBar />
        </SkillContainer>
        <SkillContainer>
          <Skill>HTML5</Skill>
          <HTMLBar />
        </SkillContainer>
        <SkillContainer>
          <Skill>CSS3</Skill>
          <CSSBar />
        </SkillContainer>
        <SkillContainer>
          <Skill>Jest</Skill>
          <JestBar />
        </SkillContainer>
      </SkillsContainer>
    </SkillsCOntainer>
  );
}

export default MySkills;
