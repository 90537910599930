import NavBar from "./Components/NavigationBar/NavBar";
import { Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import About from "./Components/About/About";
import Projects from "./Components/My-projects/Projects";
import ContactMe from "./Components/Contact-Me/ContactMe";
import MySkills from "./Components/My-Skills/MySkills";
import styled from "styled-components";
import React, { useRef, useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import Logo from "./Components/NavigationBar/Logo";
import { Link } from "react-router-dom";

// import MusicPlayer from "./Components/Music/MusicPlayer";

const Application = styled.div`
  background: #1d1d1d;
  margin: 0;
  display: flex;
  flex-direction: row;
  font-family: "san-serif";
  width: 100vw;
  overflow: hidden;
  @media (max-width: 480px) {
    padding: 0;
    marging: 0;
    height: auto;
    overflow: scroll;
  }
`;

const NavigationContainer = styled.div`
  background-color: #181818;
  width: 200px;
  padding: 4.8rem 1.6rem 4.8rem 2.4rem;
  @media (max-width: 780px) {
    width: 100px;
  }
  @media (max-width: 480px) {
    position: absolute;
    z-index: 9999;
    width: 100%;
    height: 100%;
    padding: 0;
  }
`;

const MainApllicationContainer = styled.div`
  width: 80%;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Menu = styled(AiOutlineMenu)`
  display: none;
  @media (max-width: 480px) {
    display: block;
    width: 3rem;
    height: 3rem;
    z-index: 500;
    color: #fff;
    cursor: pointer;
    position: absolute;
    top: 3.2rem;
    right: 3rem;
  }
`;

const LogoContainer = styled.div`
  display: none;
  @media (max-width: 480px) {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 3.2rem;
    left: 3rem;
  }
`;

const MusicContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: auto;
  width: auto;
  z-index: 100000;
  @media (max-width: 780px) {
    display: none;
  }
`;

function App() {
  const navContainerRef = useRef(null);

  const handleClick = () => {
    if (window.matchMedia("(max-width: 480px)").matches) {
      const navContainer = navContainerRef.current;
      navContainer.style.display = "none";
    }
  };
  const showMenu = () => {
    if (window.matchMedia("(max-width: 480px)").matches) {
      const navContainer = navContainerRef.current;
      navContainer.style.display = "block";
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const navContainer = navContainerRef.current;
      if (window.matchMedia("(min-width: 480px)").matches) {
        navContainer.style.display = "block";
      } else {
        navContainer.style.display = "none";
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Application className="App">
      {/* <CursorContainer>
        <CursorTrail />
      </CursorContainer> */}
      <MusicContainer>
        {/* <MusicPlayer /> */}
      </MusicContainer>
      <LogoContainer>
        <Link to="/">
          <Logo />
        </Link>
      </LogoContainer>
      <Menu onClick={showMenu} />
      <NavigationContainer ref={navContainerRef}>
        <NavBar handleClick={handleClick} />
      </NavigationContainer>
      <MainApllicationContainer>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/myskills" element={<MySkills />} />
          <Route path="/contact" element={<ContactMe />} />
        </Routes>
      </MainApllicationContainer>
    </Application>
  );
}

export default App;
