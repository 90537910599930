import React from "react";
import styled from "styled-components";
import AlternateCard from "./AlternateCard";
import { projectData } from "../../data/projectsData";
import Card from "./ProjectCard";

const ProjectsContainer = styled.div`
  padding: 2rem 4rem 4rem 4rem;
  display: flex;
  height: 85vh;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  font-family: "Roboto", sans-serif;
  overflow: auto;
  @media (max-width: 780px) {
    width: 100%;
    padding: 0;
  }
  @media (max-width: 780px) {
    height: 100vh;
  }

  /* hide the default scrollbar */
  &::-webkit-scrollbar {
    width: 0.5rem;
    background-color: transparent;
    z-index: 10;
  }

  /* style the thumb/handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: #08fdd8;
    cursor: pointer; /* Add this line to change cursor on hover */
  }

  /* style the thumb/handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #08fdd8;
  }
`;

const MainProjects = styled.h2`
  color: #08fdd8;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 4rem;
  font-size: 7.3rem;
  line-height: 6.7rem;
  @media (max-width: 780px) {
    font-size: 3.5rem;
    margin-bottom: 2rem;
    margin-bottom: 0rem;
  }
`;

const TechList = styled.ul`
  display: flex;
  list-style: none;
  gap: 5rem;
  @media (max-width: 780px) {
    gap: 2rem;
  }
`;

const TechItem = styled.li`
  display: block;
  font-size: 1.6rem;
  cursor: pointer;
  color: #909096;
  border-bottom: 2px solid #1d1d1d;
  &:hover {
    color: #08fdd8;
    border-bottom: 2px solid #08fdd8;
  }
  @media (max-width: 780px) {
    font-size: 1.4rem;
  }
`;

const ProjectCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 4rem;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const projectsList = projectData.map((project, index) => {
  const isEven = index % 2 === 0;
  return isEven ? (
    <AlternateCard
      key={index}
      ImagePath={project.image}
      title={project.title}
      description={project.description}
      tech={project.tech}
      link={project.link}
    />
  ) : (
    <Card
      key={index}
      ImagePath={project.image}
      title={project.title}
      description={project.description}
      tech={project.tech}
      link={project.link}
    />
  );
});

function Projects() {
  return (
    <ProjectsContainer>
      <MainProjects>Projects</MainProjects>
      <TechList>
        <TechItem>All</TechItem>
        <TechItem>JavaScript</TechItem>
        <TechItem>HTML</TechItem>
        <TechItem>CSS</TechItem>
        <TechItem>ReactJs</TechItem>
        <TechItem>NextJs</TechItem>
        <TechItem>Remix</TechItem>
        <TechItem>Jest</TechItem>
      </TechList>
      <ProjectCardsContainer>{projectsList}</ProjectCardsContainer>
    </ProjectsContainer>
  );
}

export default Projects;
