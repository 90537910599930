import React from "react";
import styled from "styled-components";

const ProjectCard = styled.a`
  height: 45rem;
  display: flex;
  align-items: center;
  position: relative;
  backgroung: red;
  @media (max-width: 780px) {
    height: 35rem;
    overflow: hidden;
    padding-right: 2rem;
  }
`;

const CardWrapper = styled.div`
  width: 30%;
  padding: 2rem;
  z-index: 1000;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 0;
  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
    background: rgb(41, 56, 83, 0.8);
  }
`;

const CardHeading = styled.h2`
  font-weight: 600;
  font-size: 4rem;
  color: #08fdd8;
  @media (max-width: 780px) {
    font-size: 2.5rem;
  }
`;

const CardDescription = styled.p`
  color: #a8b2d1;
  font-size: 1.3rem;
  padding: 4rem 2rem;
  background: #293853;
  margin: 2.5rem 0px 3rem 0;
  width: 50rem;
  @media (max-width: 780px) {
    font-size: 1rem;
    width: 40rem;
  }
  @media (max-width: 480px) {
    width: 30rem;
  }
`;

const Tech = styled.span`
  margin: 0px 20px 5px 0px;
  white-space: nowrap;
  color: #a8b2d1;
  font-size: 1.3rem;
  letter-spacing: 0.15rem;
  @media (max-width: 780px) {
    font-size: 1.2rem;
  }
`;

const ProjectImage = styled.img`
  height: 100%;
  width: 60%;
  position: absolute;
  left: 0;
  z-index: 1;
  object-fit: contain;
  @media (max-width: 780px) {
    left: 2rem;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const AlternateCard = (props) => {
  const { ImagePath, title, description, tech, link } = props;

  const techList = tech.map((technology) => (
    <Tech key={technology}>{technology}</Tech>
  ));

  return (
    <ProjectCard href={link}>
      <CardWrapper>
        <CardHeading>{title}</CardHeading>
        <CardDescription>{description}</CardDescription>
        <p>{techList}</p>
      </CardWrapper>

      <ProjectImage src={ImagePath} alt="" />
    </ProjectCard>
  );
};

export default AlternateCard;
