import React from "react";
import styled, { keyframes } from "styled-components";

const slideIn = keyframes`
  from { width: 0% }
  to { width: 25rem }
`;

const MessageStatus = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  width: 30rem;
  height: 5rem;
  background: #08fdd8;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  z-index: 999;
  animation: ${slideIn} 0.2s ease-in;
`;

const StatusText = styled.p`
  font-size: 1.6rem;
  width: 32rem;
`;

function MyModal(props) {
  const { setShowModal, showModal } = props;

  setTimeout(() => {
    setShowModal(false);
  }, 4000);

  return (
    <div>
      {showModal && (
        <MessageStatus>
          <StatusText>Your Email has been successfully sent</StatusText>
        </MessageStatus>
      )}
    </div>
  );
}

export default MyModal;
