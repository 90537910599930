import memoryGameImage from "../assets/picture/memoryGame.jpg";
import arthurShopImage from "../assets/picture/Arthurshop.png";
import restaurantAppImage from "../assets/picture/RestaurantApp.png";
import movieAppImage from "../assets/picture/movieEntertainmentApp.png";
import softCareImage from "../assets/picture/softcare.png";
import arthurappsImage from "../assets/picture/arthurapps.png";

export const projectData = [
  {
    image: restaurantAppImage,
    title: "Abites Restaurant",
    description: ` Abites Restaurant App is a convenient way for customers to order their favorite dishes and drinks from the comfort of their own home. With the app, users can browse the menu, make an order, and track the delivery status all in one place. The app also allows customers to save their frequently ordered items for even faster ordering in the future. Ordering from Abites has never been easier with the restaurant's own delivery service that brings the food to the customer's doorstep.`,
    tech: ["React", "Framer-Motion", "Firebase", "Tailwind"],
    link: "https://symphonious-sunshine-9bf734.netlify.app/",
  },
  {
    image: arthurappsImage,
    title: "Arthur's Shop",
    description: `Introducing my custom-built ecommerce application, powered by the seamless combination of React and Tailwind CSS. Showcasing my expertise in both technologies, this platform features an intuitive kanban board for project management, a visually appealing calendar for scheduling, a dynamic color-picker for product customization, and a feature-rich text-editor for crafting captivating product descriptions. With my strong React and Tailwind skills, I bring a unique touch to ecommerce and help businesses achieve their online sales goals in a streamlined and efficient manner.`,
    tech: ["React", "Tailwind", "Redux"],
    link: "https://singular-bubblegum-764c99.netlify.app/",
  },
  {
    image: arthurShopImage,
    title: "Aclassics Luxury Shop",
    description: `Aclassics is an e-commerce application that allows customers to shop for a wide variety of premium, timeless clothing and accessories. With a user-friendly interface and easy navigation, customers can browse through a curated selection of shoes, watches, and other attire from the comfort of their own home. The application also allows customers to make purchases, track their order and view their purchase history. With a focus on high-quality and classic styles, Aclassics is the perfect choice for those looking for timeless, enduring fashion.`,
    tech: ["React", "Framer-Motion", "Firebase", "sass"],
    link: "https://frolicking-youtiao-310c90.netlify.app/",
  },
  {
    image: movieAppImage,
    title: "Entertainment Application",
    description: `A movie streaming application front-end developed using Remix Run, TypeScript and CSS providing a dynamic and user-friendly interface. It is optimized for all screen sizes and devices, providing a visually appealing and modern look and feel. The application's front-end allows users to browse and stream movies, search by title and genre, and create a personal watchlist. TypeScript is used for strong type checking and CSS for the design. It provides a seamless and enjoyable streaming experience.`,
    tech: ["React", "Remix-Run", "Typescript"],
    link: "https://harmonious-pastelito-47a4e1.netlify.app/",
  },
  {
    image: memoryGameImage,
    title: "Memory Game",
    description: `Memory Match is a classic and entertaining game for players of all
    ages. The game board consists of sixteen or thirty-two cards arranged
    in a grid, with each card having a unique symbol or number on one
    side. The objective is to correctly match the pairs of cards by
    flipping over two cards at a time. The gameplay is simple, players
    take turns flipping over cards to reveal the underlying symbol or
    number. If the cards match, they stay flipped over, if not, they are
    flipped face down again. The game ends when all pairs have been
    correctly matched. Can you outsmart your opponents and win the game ?`,
    tech: ["React", "Styled Components", "Express"],
    link: "",
  },
  {
    image: softCareImage,
    title: "Soft Care",
    description: `Softcare is a team productivity software that helps digital product teams plan and track tasks while keeping an eye on larger goals. It offers advanced reports, customizable dashboard, and an all-in-one solution for communication, file storage, task tracking and document sharing, saving teams from jumping between different services. Softcare simplifies team workflow and helps maintain visibility on larger milestones, according to users. Try Softcare today to simplify your team's workflow.`,
    tech: ["HTML5", "SASS"],
    link: "https://iridescent-daffodil-5938fe.netlify.app/#",
  },
];
