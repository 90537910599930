import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import profile from "../../assets/picture/profileimg.png";

const HomeContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 7rem;
  width: 85%;
  font-family: "Roboto", sans-serif;
  @media (max-width: 780px) {
    width: 60%;
  }
  @media (max-width: 480px) {
    height: 100vh;
    width: 100vw;
    padding: 0rem;
    padding-left: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    margin: 0;
  }
`;

const MainIntro = styled.p`
  align-self: baseline;
  color: #fff;
  margin: 0;
  font-weight: 600;
  font-size: 8rem;
  @media (max-width: 780px) {
    font-weight: 600;
    font-size: 3.5rem;
  }
  @media (max-width: 1024px) {
    font-weight: 600;
    font-size: 5rem;
  }
  @media (max-width: 480px) {
    font-weight: 600;
    font-size: 4rem;
    width: 100vw;
  }
`;

const Role = styled.p`
  align-self: baseline;
  font-size: 1.6rem;
  margin-top: 0rem;
  color: #8d8d8d;
  @media (max-width: 780px) {
    font-size: 1.4rem;
    margin-top: 2rem;
  }
`;

const ContactMeBtn = styled(Link)`
  color: #08fdd8;
  font-size: 1.4rem;
  letter-spacing: 0.4rem;
  text-decoration: none;
  margin-top: 2rem;
  position: relative;
  width: 100%;
  max-width: 22rem;
  height: 5rem;
  display: block;
  border: 1px solid #08fdd8;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: baseline;
  transition: all 0.3s;
  z-index: 2;
  transition: all 0.35s ease-out-all;
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: #08fdd8;
    z-index: -1;
    transition: all 0.25s;
  }
  &:hover {
    color: #111;
    border: none;
  }
  &:hover:after {
    width: 100%;
  }
  @media (max-width: 780px) {
    margin-top: 1rem;
    max-width: 40%;
    letter-spacing: 0.25rem;
  }
  @media (max-width: 480px) {
    width: 22rem;
    padding: 0 2rem;
    margin: 0;
    font-weight: 600;
    font-size: 1.4rem;
    border: 2px solid #08fdd8;
  }
`;

const ProfileImg = styled.img`
  height: 100vh;
  position: absolute;
  right: 8rem;
  @media (max-width: 780px) {
    height: 80%;
    right: 1rem;
  }
  @media (max-width: 1024px) {
    width: 40%;
    height: auto;
    right: 4rem;
    bottom: 0;
  }
  @media (max-width: 480px) {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
  }
`;

const MainIntroContainer = styled.div`
  margin: 0;
  width: 80%;
  z-index: 5;
  @media (max-width: 480px) {
    width: 100vw;
  }
`;

function Home() {
  return (
    <HomeContainer>
      <MainIntroContainer>
        <MainIntro>Hi,</MainIntro>
        <MainIntro>I'm Arthur,</MainIntro>
        <MainIntro>A web developer</MainIntro>
        <Role>Front End Developer</Role>
        <ContactMeBtn to="/contact"> Contact me!</ContactMeBtn>
      </MainIntroContainer>
      <ProfileImg src={profile} alt="arthur image" />
    </HomeContainer>
  );
}

export default Home;
